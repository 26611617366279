import IconCustomSVG from "../assets/IconCustom.svg";

import IconCheckSVG from "../assets/IconCheck.svg";
import IconSecureSVG from "../assets/IconSecure.svg";
import IconNoSVG from "../assets/IconNo.svg";

import MayoPNG from "../assets/mayo.png";
import StanfordPNG from "../assets/stanford.png";
import HMSPNG from "../assets/hms.png";
import CaltechPNG from "../assets/caltech.png";
import UCLAPNG from "../assets/ucla.png";

import LydiaDeploymentJPEG from "../assets/LydiaDeployment.jpeg";
import LoginPNG from "../assets/login.png";

import "../App.css";

function LandingScreenMobile() {
  return (
    <div style={{ backgroundColor: "#F6F4F6", width: "100%" }}>
      {/* Header */}
      <div
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 900,
            fontSize: "20px",
            color: "#54007A",
          }}
        >
          Lydia Technologies
        </p>
        <a
          className="LoginButton"
          href="https://lydia-20cda.web.app/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            padding: "8px 16px",
            backgroundColor: "#54007A",
            color: "white",
            textDecoration: "none",
            borderRadius: "5px",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          Log In
        </a>
      </div>

      {/* Hero Section */}
      <div style={{ padding: "20px", textAlign: "center" }}>
        <img
          src={LoginPNG}
          alt="LydiaDeployment"
          style={{
            width: "100%",
            maxWidth: "300px",
            height: "auto",
            marginBottom: "20px",
          }}
        />
        <h1
          style={{ fontSize: "32px", fontWeight: "700", marginBottom: "10px" }}
        >
          Streamline Your Care with{" "}
          <span style={{ color: "#54007A" }}>Gen AI</span>
        </h1>
        <p style={{ fontSize: "16px", marginBottom: "20px" }}>
          Custom back-office and RCM automations in as little as 24 hours.
        </p>
        <a
          className="ContactButton"
          href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block", marginBottom: "10px" }}
        >
          <p style={{ fontWeight: "400", fontSize: "14px" }}>Request Demo</p>
        </a>
        <p style={{ fontSize: "14px" }}>
          Enterprise-grade consulting, risk free.
        </p>
      </div>

      {/* How Lydia Works */}
      <div
        style={{
          backgroundColor: "white",
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <h2
          style={{ fontSize: "24px", fontWeight: "700", marginBottom: "20px" }}
        >
          How <span style={{ color: "#54007A" }}>Lydia</span> works
        </h2>
        <p style={{ fontSize: "16px", marginBottom: "20px" }}>
          We train our AI agents to follow your clinic's SOPs and integrate with
          your existing systems.
        </p>
        <img
          src={LydiaDeploymentJPEG}
          alt="How"
          style={{ width: "100%", maxWidth: "400px", height: "auto" }}
        />
      </div>

      {/* Why Choose Lydia */}
      <div style={{ padding: "40px 20px", textAlign: "center" }}>
        <h2
          style={{ fontSize: "28px", fontWeight: "700", marginBottom: "30px" }}
        >
          Why Choose Lydia Technologies
        </h2>
        {/* Feature boxes */}
        {[
          {
            icon: IconCheckSVG,
            title: "Proven Results",
            description: (
              <>
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  97%+
                </span>{" "}
                Average clean claim rate
              </>
            ),
          },
          {
            icon: IconCustomSVG,
            title: "Custom Solution",
            description: (
              <>
                Tailored to your{" "}
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  specific needs
                </span>{" "}
                (claims submission, referral management, eligibility
                verification, etc.)
              </>
            ),
          },
          {
            icon: IconNoSVG,
            title: "Zero Risk",
            description: (
              <>
                Pay only per completed task after full implementation +{" "}
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  $0 upfront fees
                </span>{" "}
                and no commitment necessary
              </>
            ),
          },
          {
            icon: IconSecureSVG,
            title: "Secure",
            description: (
              <>
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  HIPAA
                </span>{" "}
                and{" "}
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  SOC 2
                </span>{" "}
                compliant
              </>
            ),
          },
        ].map((feature, index) => (
          <div key={index} style={{ marginBottom: "30px" }}>
            <img
              src={feature.icon}
              alt={feature.title}
              style={{
                height: feature.icon === IconCustomSVG ? "100px" : "50px",
                marginBottom: "10px",
              }}
            />
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              {feature.title}
            </h3>
            <p style={{ fontSize: "14px" }}>{feature.description}</p>
          </div>
        ))}
        <a
          className="ContactButton"
          href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block", margin: "20px 0" }}
        >
          <p style={{ fontWeight: "400", fontSize: "14px" }}>Request Demo →</p>
        </a>
      </div>

      {/* Expertise Section */}
      <div
        style={{
          backgroundColor: "white",
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <h2
          style={{ fontSize: "24px", fontWeight: "700", marginBottom: "20px" }}
        >
          Expertise from Leading Institutions
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            src={StanfordPNG}
            style={{ height: "40px", width: "auto" }}
            alt="Stanford"
          />
          <img
            src={HMSPNG}
            style={{ height: "80px", width: "auto" }}
            alt="Harvard Medical School"
          />
          <img
            src={MayoPNG}
            style={{ height: "80px", width: "auto" }}
            alt="Mayo Clinic"
          />
          <img
            src={UCLAPNG}
            style={{ height: "40px", width: "auto" }}
            alt="UCLA"
          />
          <img
            src={CaltechPNG}
            style={{ height: "80px", width: "auto" }}
            alt="Caltech"
          />
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          backgroundColor: "black",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <p style={{ color: "white", fontSize: "14px" }}>
          © 2024 Lydia Technologies. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default LandingScreenMobile;
