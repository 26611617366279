import IconCustomSVG from "../assets/IconCustom.svg";

import IconCheckSVG from "../assets/IconCheck.svg";
import IconSecureSVG from "../assets/IconSecure.svg";
import IconNoSVG from "../assets/IconNo.svg";

import MayoPNG from "../assets/mayo.png";
import StanfordPNG from "../assets/stanford.png";
import HMSPNG from "../assets/hms.png";
import CaltechPNG from "../assets/caltech.png";
import UCLAPNG from "../assets/ucla.png";

import LydiaDeploymentJPEG from "../assets/LydiaDeployment.jpeg";
import LoginPNG from "../assets/login.png";
import LydiaLogoJPG from "../assets/LydiaLogoPurpleBackground.jpg";


import "../App.css";

function LandingScreen() {
  return (
    <div
      style={{
        backgroundColor: "#F6F4F6",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 5%",
            height: "10vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                marginBlock: "0px",
                fontWeight: 900,
                fontSize: "26px",
                color: "#54007A",
              }}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <img src={LydiaLogoJPG} alt="Lydia Logo" style={{ height: '26px', marginRight: '10px', verticalAlign: 'middle' }} />
                Lydia Technologies
              </span>
              {/* Lydia Technologies */}
            </p>
          </div>
          <a
            className="LoginButton"
            href="https://lydia-20cda.web.app/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              padding: "10px 20px",
              backgroundColor: "#54007A",
              color: "white",
              textDecoration: "none",
              // borderRadius: "5px",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Log In
          </a>
        </div>
        <div
          style={{
            height: "90vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              padding: "10vw",
            }}
          >
            <img
              src={LoginPNG}
              className="HeroSectionImage"
              alt="LydiaDeployment"
              style={{ width: "100%", height: "auto" }} // Responsive image sizing
            />
          </div>
          <div
            style={{
              flex: 1,
              width: "100%",
            }}
            className="fadein"
          >
            <p
              style={{
                marginBlock: "0px",
                fontWeight: "700",
                fontSize: "60px",
              }}
            >
              Streamline Your <br />
              Care with <span style={{ color: "#54007A" }}> Gen AI</span> <br />
            </p>
            <p
              style={{
                margin: "3vh 0 0 0",
                fontWeight: "400",
                fontSize: "18px",
                maxWidth: "30vw",
              }}
            >
              Custom back-office and RCM automations in as little as 24 hours.{" "}
            </p>
            <div
              style={{
                display: "flex",
                marginTop: "3vh",
                height: "2.5em",
                alignItems: "center",
              }}
            >
              <a
                className="ContactButton"
                href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                target="_blank"
              >
                <p
                  style={{
                    //   color: "white",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  Request Demo
                </p>
              </a>
              <p
                style={{
                  margin: "0 20px",
                  color: "black",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Enterprise-grade consulting, risk free.
              </p>
            </div>
          </div>
        </div>
      </>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "8vh 5%",
          // height: "100vh",
          backgroundColor: "white",
        }}
        className="fadein"
      >
        <p
          style={{
            marginBlock: "0px",
            fontWeight: "700",
            fontSize: "30px",
          }}
        >
          How <span style={{ color: "#54007A" }}>Lydia</span> works
        </p>
        <p
          style={{
            margin: "3vh 0 5vh 0",
            fontWeight: "400",
            fontSize: "18px",
          }}
        >
          We train our AI agents to follow your clinic's SOPs and integrate with
          your existing systems.
        </p>
        <img src={LydiaDeploymentJPEG} className="HowWorksImage" alt="How" />
        <div className="orb1 floating" />
        <div className="orb2 floating" />
        <div className="orb3 floating" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 5%",
          // height: "100vh",
          // backgroundColor: "white",
        }}
      >
        <p
          style={{
            margin: "1em",
            color: "black",
            fontWeight: "700",
            fontSize: "40px",
            textAlign: "center",
          }}
        >
          Why Choose Lydia Technologies
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconCheckSVG}
                style={{ height: "4em", width: "auto" }}
                alt="IconCheckSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "black",
                }}
              >
                Proven Results
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  97%+
                </span>{" "}
                Average clean claim rate
              </p>
            </div>
          </div>
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconCustomSVG}
                style={{ height: "5em", width: "auto" }}
                alt="IconCustomSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "black",
                }}
              >
                Custom Solution
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                Tailored to your{" "}
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  {" "}
                  specific needs{" "}
                </span>{" "}
                (claims submission, referral management, eligibility
                verification, etc.)
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconNoSVG}
                style={{ height: "3em", width: "auto", paddingBottom: "1em" }}
                alt="IconNoSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "black",
                }}
              >
                Zero Risk
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                Pay only per completed task after full implementation +
                <br />{" "}
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  $0 upfront fees
                </span>{" "}
                and no commitment necessary
              </p>
            </div>
          </div>
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconSecureSVG}
                style={{ height: "3em", width: "auto", paddingBottom: "1em" }}
                alt="IconSecureSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "black",
                }}
              >
                Secure
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  HIPAA
                </span>{" "}
                and{" "}
                <span style={{ color: "#54007A", fontWeight: "bold" }}>
                  SOC 2
                </span>{" "}
                compliant{" "}
              </p>
            </div>
          </div>
        </div>
        <a
          className="ContactButton"
          href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
          target="_blank"
          style={{ margin: "2em" }}
        >
          <p
            style={{
              //   color: "white",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            Request Demo →
          </p>
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "8vh 5%",
          backgroundColor: "white",
        }}
      >
        <p
          style={{
            marginBlock: "0px",
            fontWeight: "700",
            fontSize: "30px",
            textAlign: "center",
            margin: "2vh 0 4vh",
          }}
        >
          Expertise from Leading Institutions
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "4em",
          }}
        >
          <img
            src={StanfordPNG}
            style={{ height: "60px", width: "auto", marginRight: "2em" }}
            alt="Stanford"
          />
          <img
            src={HMSPNG}
            style={{ height: "120px", width: "auto" }}
            alt="Harvard Medical School"
          />
          <img
            src={MayoPNG}
            style={{ height: "120px", width: "auto" }}
            alt="Mayo Clinic"
          />
          <img
            src={UCLAPNG}
            style={{ height: "60px", width: "auto" }}
            alt="UCLA"
          />
          <img
            src={CaltechPNG}
            style={{ height: "120px", width: "auto" }}
            alt="Caltech"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 5%",
          backgroundColor: "black",
        }}
      >
        <div>
          <p
            style={{
              color: "white",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            © 2024 Lydia Technologies. All Rights Reserved. <br /> <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingScreen;
